<template>
  <div class="objects-footer relative w-full text-right px-5">
    <el-button class="objects-footer__cancel border-0" @click="$emit('onCancel')">{{ $t('new_units.footer.cancel') }} </el-button>
    <el-button class="objects-footer__save border-0" @click="$emit('onSubmit')">{{ $t('new_units.footer.save') }} </el-button>
  </div>
</template>

<script setup>
import { useI18n } from '@/hooks/useI18n'
import { createVuexHelpers } from 'vue2-helpers'

const { useGetters } = createVuexHelpers()

const { errors } = useGetters('units', ['errors'])

const $t = useI18n()
</script>

<style lang="scss">
@import '@/components/unitsNew/assets/styles/UnitsFooter.scss';
</style>
