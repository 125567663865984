<template>
  <div
    :class="[
      'object-remove-button',
      'flex',
      'items-center',
      'gap-1',
      { active: deletedCount }
    ]"
  >
    <span
      :class="[
        'object-remove-button__title',
        { 'cursor-pointer': deletedCount }
      ]"
      @click="$emit('onDelete')"
      >{{ $t('new_units.delete') }}</span
    >
    <div class="object-remove-button__count flex justify-center items-center">
      {{ props.deletedCount }}
    </div>
  </div>
</template>

<script setup>
import { useI18n } from '@/hooks/useI18n'

const $t = useI18n()

const props = defineProps({
  deletedCount: {
    type: Number,
    default: 0
  }
})
</script>

<style lang="scss">
@import '@/components/unitsNew/assets/styles/UnitsDeleteButton.scss';
</style>
