var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"object-sensors__details__table"},[_c('div',{staticClass:"object-sensors__details__table__header flex items-center justify-between pr-four-tenths pl-3"},[_vm._l((_vm.headers),function(col){return _c('span',{key:col,staticClass:"w-1/2 mainTitleColor font-bold"},[_vm._v(_vm._s(col))])}),(_vm.isFirstTable)?_c('el-popover',{attrs:{"placement":"bottom","trigger":"click","popper-class":"sizeScreenReport","disabled":_vm.isDisabled},model:{value:(_setup.isClearPopover),callback:function ($$v) {_setup.isClearPopover=$$v},expression:"isClearPopover"}},[_c('div',{staticClass:"bg-white rounded-md p-1 border-solid border-lightGrey-300"},[_c('el-button',{staticClass:"border-0 bg-red-200 text-white font-semibold cursor-pointer",on:{"click":_setup.clearTable}},[_vm._v(_vm._s(_vm.$t('new_units.objects.fields.sensors.fields.parameters.clear'))+" ")])],1),_c(_setup.RedCrossIcon,{staticClass:"cursor-pointer",attrs:{"slot":"reference"},slot:"reference"})],1):_vm._e()],2),_vm._l((_vm.coefficients),function(item,itemIdx){return _c('div',{key:item.id,staticClass:"object-sensors__details__table__content flex items-center",class:{ error: _vm.notValidRows.includes(itemIdx) }},[_vm._l((item),function(value,key){return _c('div',{key:item.id + '-' + key},[(key !== 'id')?_c('el-input',{class:{
          'not-valid':
            _vm.errors.includes(itemIdx) && (key === 'x' || key === 'fromValue')
        },attrs:{"type":"number"},on:{"input":function($event){return _vm.$emit('onInput', { itemIdx, key, value: $event })},"focus":function($event){return _vm.$emit('onFocus', { itemIdx, key })},"blur":function($event){return _vm.$emit('onBlur', { itemIdx, key })}},model:{value:(_vm.coefficients[itemIdx][key]),callback:function ($$v) {_vm.$set(_vm.coefficients[itemIdx], key, $$v)},expression:"coefficients[itemIdx][key]"}}):_vm._e()],1)}),_c('div',{staticClass:"object-sensors__details__table__content--remove flex items-center justify-center py-1 px-2 bg-white"},[_c(_setup.BlackCrossIcon,{class:[
          'cursor-pointer',
          { 'remove-disabled': _setup.getDisabledRemoveIcon(_vm.coefficients, itemIdx) }
        ],nativeOn:{"click":function($event){return _vm.$emit('onDeleteRow', { itemIdx })}}})],1)],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }