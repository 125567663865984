<template>
	<div class="flex w-full">
		<div class="relative filter-container">
			<div class="absolute pl-3 inset-y-0 flex items-center z-10 cursor-pointer" @click="$emit('onSearch')">
				<SearchIcon />
			</div>
			<el-select
				v-model="searchOption"
				popper-class="filtration"
				filterable
        placeholder="Объекты"
				type="text"
				size="large"
				class="filtration-select"
				@change='handleOptionChange'
			>
				<el-option
					v-for="(item, index) in options"
					:key="index"
					:label="item.key"
					:value="item.value"
				/>
			</el-select>
		</div>
		<div class="relative cursor-pointer w-full">
			<input
				v-if="searchOption !== 'sensors.type.key'"
				v-model="model"
				type="text"
				:placeholder="$t(`new_units.${tab}.search`)"
				class="border-l-0 rounded-br-xl rounded-tr-xl focus_outline-none pl-4 pr-8 w-full text-sm font-medium border border-borderColor search-container"
				@keyup.enter="$emit('onSearch')"
			>
			<el-select
				v-else
				v-model="selectedSensorType"
				popper-class="filtration"
				filterable
				type="text"
				size="large"
				class="sensors-filtration-select"
				@change="handleFilterBySensor"
			>
				<el-option
					v-for="(item, index) in sensorTypes"
					:key="index"
					:label="item.value"
					:value="item.key"
				/>
			</el-select>
		</div>
	</div>
</template>
<script setup>
import { ref, computed, watch } from 'vue';

import { createVuexHelpers } from 'vue2-helpers';

import SearchIcon from '../assets/icons/search.vue';

const { useGetters } = createVuexHelpers();

const { sensorTypes } = useGetters('dictionary', ['sensorTypes'])

const searchOption = ref('')
const selectedSensorType = ref('')

const emits = defineEmits(['onInput', 'filter', 'onOptionChange', 'onSensorChange'])

const props = defineProps({
	value: {
		type: String,
		default: '',
	},
	searchModel: {
		type: String,
		default: ''
	},
	options: {
		type: Array,
		default: () => []
	},
	tab: {
		type: String,
		default: ''
	},
})

const model = computed({
	get() {
		return props.value
	},
	set(val) {
		emits('onInput', val)
	},
})

const handleOptionChange = (val) => {
	emits('onOptionChange', val)
}

const handleFilterBySensor = () => {
	emits('onSensorChange', selectedSensorType.value)
}

watch(() => props.searchModel, (val) => {
	searchOption.value = val
}, { immediate: true })
</script>

<style lang="scss">
@import '@/components/unitsNew/assets/styles/UnitsSearchInput.scss';
</style>
