import { render, staticRenderFns } from "./UnitsAddButton.vue?vue&type=template&id=04a503c3&scoped=true"
import script from "./UnitsAddButton.vue?vue&type=script&setup=true&lang=js"
export * from "./UnitsAddButton.vue?vue&type=script&setup=true&lang=js"
import style0 from "./UnitsAddButton.vue?vue&type=style&index=0&id=04a503c3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a503c3",
  null
  
)

export default component.exports