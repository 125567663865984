<template>
  <div class="object-sensors flex">
    <SensorsList :data="sensorsList" :selected-sensor="selectedSensor.id" :recovered="recovered" :is-all-checked="isAllChecked" @onChange="changeSensor" @onAdd="addSensor" @onDelete="deleteSensor" @onCancel="cancelDeletion" @onDeleteRecovered="finalDeletion" @addCopySensors="addCopySensors" @updateData="updateData" @onAllChecked="isAllChecked = $event" @onToggleCheckbox="toggleCheckbox" />
    <SensorsDetails v-if="sensorsList.length || isNewSensor" :sensor="selectedSensor.item" :sensor-id="selectedSensor.id" :sensor-calibration="sensorCalibration" :data="sensorsList" :is-all-checked="isAllChecked" @onCalibrationChange="calibrationChangeHandler" @toggleSensorGraph="sensorCalibration = $event" @changeSensorCalibration="sensorCalibration = $event" @onCancelMassiveDeletion="isAllChecked = false" />
  </div>
</template>
<script setup>
import { ref, onBeforeMount, getCurrentInstance, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'

import { validateSection, refs } from '@/components/unitsNew/helpers/index.js'

import SensorsList from '@/components/unitsNew/components/Objects/Sections/Sensors/SensorsList.vue'
import SensorsDetails from '@/components/unitsNew/components/Objects/Sections/Sensors/SensorsDetails.vue'

const instance = getCurrentInstance()

const { useGetters, useMutations, useActions } = createVuexHelpers()
const $t = useI18n()

const { activeObject, sensorTableError, isSubmit } = useGetters('units', ['activeObject', 'sensorTableError', 'isSubmit'])
const { SET_SENSORS_ACTIVE_OBJECT, SET_ERRORS, SET_SENSOR_CALIBRATION } = useMutations('units', ['SET_SENSORS_ACTIVE_OBJECT', 'SET_ERRORS', 'SET_SENSOR_CALIBRATION'])
const { updateSensors } = useActions('units', ['updateSensors'])

const sensorsList = ref([])

const selectedSensor = ref({
  id: uuidv4(),
  item: {},
  idx: null
})

const isAllChecked = ref(false)

const isNewSensor = ref(false)

const deleteTime = 10000
const recovered = ref([])
const activeTab = 'sensors'

let myTimeout = null

const sensorCalibration = ref({})

const toggleCheckbox = sensor => {
  sensorsList.value[sensor.idx].checked = sensor.value
}

const makeSensorCalibrationObject = () => {
  console.log(selectedSensor.value.item.min_valid, 'selectedSensor.value.item.min_valid')
  sensorCalibration.value = {
    isGraphShown: selectedSensor.value.item.isGraphShown,
    calibrationABlist: selectedSensor.value.item.calibrationABlist,
    calibrationXYlist: selectedSensor.value.item.calibrationXYlist,
    isSensorCalibration: true,
    min_valid: selectedSensor.value.item.min_valid,
    max_valid: selectedSensor.value.item.max_valid,
    is_string_value: selectedSensor.value.item.is_string_value
  }
}

const calibrationChangeHandler = ({ table, values }) => {
  const currentTable = table === 'calibrationABlist' ? 'calibrationXYlist' : 'calibrationABlist'
  selectedSensor.value.item[table] = values

  SET_SENSOR_CALIBRATION({ table, values, id: selectedSensor.value.id })
  SET_SENSOR_CALIBRATION({
    currentTable,
    values: selectedSensor.value.item[currentTable],
    id: selectedSensor.value.id
  })
}

const validateSensorTechParams = async () => {
  let isValid = true
  if (refs.sensorTechParametersValidation.value) {
    await refs.sensorTechParametersValidation.value.validate(valid => {
      isValid = valid
    })
  }

  return isValid
}

const changeSensor = async ({ id, idx }) => {
  const isSensorTechParamsValid = await validateSensorTechParams()

  validateSection(activeTab, valid => {
    if (!valid || sensorTableError.value || !isSensorTechParamsValid) return instance.proxy.$showError($t('new_units.objects.fields.sensors.fields.errors.validation'))

    SET_ERRORS([])
    selectedSensor.value.id = id
    selectedSensor.value.item = sensorsList.value.find(item => item.id === id)
    selectedSensor.value.idx = idx

    addCalibration()
    makeSensorCalibrationObject()
  })
}

const addSensor = () => {
  const ref = validateSection(activeTab, valid => {
    if (!valid) return instance.proxy.$showError($t('new_units.objects.fields.sensors.fields.errors.validation'))

    addSensorHandler()
  })

  if (!ref) addSensorHandler()
}

const addCopySensors = items => {
  items.forEach(val => {
    if (val.sensors) {
      val.sensors.forEach(sensor => {
        if (sensor.checked) {
          const newSensor = {
            isNew: true,
            ...sensor,
            id: uuidv4(),
            validator_sensor: null,
            validator_type: null
          }

          selectedSensor.value.id = newSensor.id
          selectedSensor.value.item = newSensor

          addCalibration()
          makeSensorCalibrationObject()

          sensorsList.value.push(newSensor)
          SET_SENSORS_ACTIVE_OBJECT(sensorsList.value)
        }
      })
    }
  })
}

const addSensorHandler = () => {
  isNewSensor.value = true

  const newSensor = {
    id: uuidv4(),
    formula: '',
    hide_in_monitoring: false,
    hide_in_reports: false,
    is_string_value: false,
    invalid: false,
    checked: false,
    custom_fields: [],
    connected_sensors: [],
    smoothing: 0,
    filter_type: {},
    name: $t('new_units.objects.fields.sensors.fields.new_sensor'),
    calibrationXYlist: [],
    calibrationABlist: [],
    isGraphShown: false,
    param1: {
      param_key: '',
      calibrationXYlist: [],
      calibrationABlist: []
    },
    param2: {
      param_key: '',
      calibrationXYlist: [],
      calibrationABlist: []
    },
    type: '',
    isNew: true,
    string_converters: [
      {
        x: '',
        y: {
          value: ''
        }
      }
    ]
  }

  selectedSensor.value.id = newSensor.id
  selectedSensor.value.item = newSensor

  makeSensorCalibrationObject()

  sensorsList.value.push(newSensor)

  SET_SENSORS_ACTIVE_OBJECT(sensorsList.value)
}

const finalDeletion = () => {
  if (!recovered.value.length) return

  isNewSensor.value = false

  sensorsList.value = sensorsList.value.filter(item => !recovered.value.includes(item.id))

  sensorsList.value.forEach(sensor => {
    if (sensor.validator_sensor && recovered.value.includes(sensor.validator_sensor.id)) {
      sensor.validator_sensor = sensor.validator_type = null
    }
  })

  const list = sensorsList.value

  if (recovered.value.includes(selectedSensor.value.id)) {
    SET_ERRORS([])
  }

  if (list.length) {
    selectedSensor.value.id = list[list.length - 1].id
    selectedSensor.value.item = list[list.length - 1]
  } else {
    selectedSensor.value.id = ''
    selectedSensor.value.item = {}
  }

  recovered.value = []

  clearTimeout(myTimeout)

  SET_SENSORS_ACTIVE_OBJECT(sensorsList.value)
}

const deletionTimeout = () => {
  myTimeout = setTimeout(finalDeletion, deleteTime)
}

const deleteSensor = sensor => {
  console.log('deleteSensor sensor', sensor)
  if (recovered.value.includes(sensor.id)) return instance.proxy.$showError('Вы уже удалили этот датчик, если вы хотите вернуть нажмите на кнопку отмены ниже.')

  recovered.value.push(sensor.id)

  clearTimeout(myTimeout)
  deletionTimeout()
}

const cancelDeletion = () => {
  recovered.value.splice(0)

  clearTimeout(myTimeout)
}

const addCalibration = () => {
  selectedSensor.value.item.isGraphShown = false

  if (!selectedSensor.value.item.calibrationXYlist) {
    selectedSensor.value.item.calibrationXYlist = []
    selectedSensor.value.item.calibrationABlist = []
  }
}

watch(activeObject, () => {
  makeData(selectedSensor.value.idx)
})

watch(isSubmit, val => {
  if (val) finalDeletion()
})

const makeData = idx => {
  if (activeObject.value.sensors) {
    sensorsList.value = activeObject.value.sensors || []
  }

  if (sensorsList.value.length) {
    selectedSensor.value.id = sensorsList.value[idx].id
    selectedSensor.value.item = sensorsList.value[idx]
    selectedSensor.value.idx = idx

    sensorsList.value.forEach(item => {
      if (!item.custom_fields) item.custom_fields = []
      if (!item.connected_sensors) item.connected_sensors = []
      if (item.checked === undefined) item.checked = false
    })

    addCalibration()
    makeSensorCalibrationObject()
  }
}

onBeforeMount(() => {
  makeData(0)
})
const updateData = ({ data, idx }) => {
  sensorsList.value = data
  updateSensors(sensorsList.value)
  selectedSensor.value.idx = idx
}
</script>
<style lang="scss">
@import '@/components/unitsNew/assets/styles/Objects/Sensors/Sensors.scss';
</style>
