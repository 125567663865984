<template>
  <div class="object-sensors__details__deletion_overlay">
    <NoteInfoLargeIcon />
    <span>{{
      $t('new_units.objects.fields.sensors.fields.multiple_deletion_mode')
    }}</span>
    <button @click="$emit('onCancel')">
      {{ $t('cancel') }}
    </button>
  </div>
</template>
<script setup>
import NoteInfoLargeIcon from '@/components/unitsNew/assets/icons/note-info_large.vue'
import { useI18n } from '@/hooks/useI18n'

const $t = useI18n()
</script>
<style lang="scss">
@import '@/components/unitsNew/assets/styles/Objects/Sensors/SensorsDetailsDeletionOverlay.scss';
</style>
