<template>
	<div class="flex gap-3 items-center justify-end cursor-pointer">
		<PlusIcon />
		<span v-if="activeTab" class="text-base m-w curiousBlue">{{ $t(`new_units.add.${activeTab}`) }}</span>
	</div>
</template>

<script setup>
import PlusIcon from '../assets/icons/plus.vue'
import { useI18n } from '@/hooks/useI18n'

const props = defineProps(['activeTab'])
const $t = useI18n()


</script>

<style lang="scss" scoped>
.m-w {
	width: max-content;
}
</style>
