<template>
  <div class="objects-recover__wrapper pl-3">
    <Transition name="slide-fade">
      <div
        class="objects-recover h-full flex justify-between items-center cooldownClass"
      >
        <div class="objects-recover__left h-full flex p-3 pl-0 w-9/12">
          <div class="left-line mr-3"></div>
          <div class="flex flex-col">
            <div class="flex items-center min-w-full">
              <div class="text-black min-w-full">
                {{ $t(`new_units.recover.removed.${activeTab}`) }}
              </div>
              <div class="text-black">{{ count }}</div>
            </div>
            <el-button
              type="text"
              class="p-0 curiousBlue font-semibold text-left"
              @click="$emit('onCancel')"
            >
              {{ $t('new_units.recover.button') }}
            </el-button>
          </div>
        </div>
        <div
          class="objects-recover__right flex justify-end bg-white mr-3 text-right relative"
        >
          <div
            class="objects-recover__right__wrapper flex items-center justify-center w-8 h-8 text-center rounded-full cursor-pointer"
            @click="$emit('onDeleteRecovered')"
          >
            <el-progress
              class="absolute"
              type="circle"
              status="exception"
              :show-text="false"
              :percentage="time"
              :width="progressWidth"
              :stroke-width="strokeWidth"
              @click.prevent
            />
            <DeleteIcon class="delete-icon" />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script setup>
import { ref, onBeforeMount, onBeforeUnmount, watch } from 'vue'

import { useI18n } from '@/hooks/useI18n'

import DeleteIcon from '../assets/icons/delete.vue'

const $t = useI18n()

const props = defineProps({
  count: {
    type: Number,
    default: 0
  },
  activeTab: {
    type: String,
    default: ''
  }
})

watch(
  () => props.count,
  () => {
    const block = document.querySelector('.objects-recover')

    block.classList.remove('cooldownClass')

    setTimeout(() => {
      block.classList.add('cooldownClass')
    }, 100)

    time.value = 0

    clearInterval(myInterval.value)
    deletionTiming()
  }
)

let myInterval = ref(null)
const time = ref(0)
const progressWidth = 40
const strokeWidth = 3
const timeCount = 1
const intervalDelay = 100

const deletionTiming = () => {
  myInterval.value = setInterval(() => (time.value += timeCount), intervalDelay)
}
onBeforeMount(() => {
  deletionTiming()
})

onBeforeUnmount(() => {
  clearInterval(myInterval.value)
})
</script>

<style lang="scss">
@import '@/components/unitsNew/assets/styles/UnitsDeletedRecovery.scss';
</style>
