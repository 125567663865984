<template>
  <div class="flex flex-col justify-between h-full">
    <div v-loading="loading" class="h-full overflow-hidden">
      <UnitsHeader :active-tab="modalType" is-modify @onBack="handleBack" @onShowHistory="handleShowHistory" />
      <ObjectsTabs :tab="activeTab" :is-current="isCurrent" :is-archive-edit="isArchiveEdit" @onChange="onTabChange" />
      <component :is="activeComponent" :data="data" @save-object="saveObject" @changePeriod="getDataByDate" />
    </div>
    <UnitsFooter v-if="!isArchiveEdit" class="self-end" @onCancel="handleBack" @onSubmit="submitObject" />
  </div>
</template>

<script setup>
import { ref, watch, defineAsyncComponent, onBeforeMount, getCurrentInstance, computed, onMounted } from 'vue'

import { unitTrailersApi, unitDriversApi } from '@/api'
import { validateSection } from '@/components/unitsNew/helpers/index.js'

import UnitsHeader from '../UnitsHeader.vue'
import ObjectsTabs from './ObjectsTabs.vue'
import UnitsFooter from '../UnitsFooter.vue'
import Basic from '@/components/unitsNew/components/Objects/Sections/Basic/Basic.vue'
import Sensors from '@/components/unitsNew/components/Objects/Sections/Sensors/Sensors.vue'

import sections from '@/enums/newUnits/objects-sections.js'

import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'
import { refs } from '../../helpers'
import { useStore } from '@/store/store'

const Store = useStore()
const isArchiveEdit = computed(() => Store.getters['archive/getOpenEdit'])

const { useGetters, useMutations, useActions } = createVuexHelpers()

const { activeObject, modalType, errors, sensorTableError } = useGetters('units', ['activeObject', 'modalType', 'errors', 'sensorTableError'])
const { userById } = useGetters('users', ['userById'])

const { SET_COUPLINGS_ACTIVE_OBJECT, SET_SHIFTS_ACTIVE_OBJECT, SET_ACTIVE_OBJECT, SET_ERRORS, SET_IS_SUBMIT, SET_INVALID_ELEMENTS } = useMutations('units', ['SET_COUPLINGS_ACTIVE_OBJECT', 'SET_SHIFTS_ACTIVE_OBJECT', 'SET_ACTIVE_OBJECT', 'SET_ERRORS', 'SET_IS_SUBMIT', 'SET_INVALID_ELEMENTS'])

const { CREATE_UNIT, UPDATE_UNIT, VALIDATE_UNIT } = useActions('units', ['CREATE_UNIT', 'UPDATE_UNIT', 'VALIDATE_UNIT'])

const $t = useI18n()
const instance = getCurrentInstance()
const emits = defineEmits('onBack', 'onSubmit', 'onShowHistory')
const activeTab = ref(sections.BASIC)
const isCurrent = ref(false)
const loading = ref(false)

watch(
  () => activeTab.value,
  () => {
    Store.commit('archive/setActiveTab', activeTab.value)
  }
)

const activeComponent = ref(Basic)

const data = ref({
  id: '',
  type: {
    key: 'car',
    type: 'unit_type',
    value: 'Легковой'
  },
  icon: {
    name: 'car',
    color: 'red'
  },
  sensors: []
})

const getIcon = () => {
  if (data.value.icon.name && data.value.icon.color) {
    return {
      key: `${data.value.icon.name}_${data.value.icon.color}`
    }
  }

  return data.value.icon
}

const checkSensorsTableValues = item => {
  const isEmpty = Object.values(item).every(value => value.toString())

  if (isEmpty) {
    return item
  }
}

const getValidValues = section => {
  if (section === sections.SENSORS && activeObject.value[section]) {
    const sensors = activeObject.value[section]

    sensors.forEach(sensor => {
      sensor.param1.calibrationABlist = sensor.param1.calibrationABlist.filter(checkSensorsTableValues)
      sensor.param1.calibrationXYlist = sensor.param1.calibrationXYlist.filter(checkSensorsTableValues)

      sensor.param2.calibrationABlist = sensor.param2.calibrationABlist.filter(checkSensorsTableValues)
      sensor.param2.calibrationXYlist = sensor.param2.calibrationXYlist.filter(checkSensorsTableValues)

      if (sensor.calibrationABlist && sensor.calibrationXYlist) {
        sensor.calibrationABlist = sensor.calibrationABlist.filter(checkSensorsTableValues)

        sensor.calibrationXYlist = sensor.calibrationXYlist.filter(checkSensorsTableValues)
      }
    })
  }

  return activeObject.value[section]
    ? JSON.parse(JSON.stringify(activeObject.value[section])).map(item => {
        if (item.isNew) item.id = null

        return item
      })
    : []
}

const validateSectionHandler = async () => {
  if (sensorTableError.value && activeTab.value === sections.SENSORS) {
    return instance.proxy.$showError($t('new_units.objects.fields.sensors.fields.errors.save_validation'))
  }

  let isValid = true
  if (refs.sensorTechParametersValidation.value) {
    await refs.sensorTechParametersValidation.value.validate(valid => {
      isValid = valid
    })
  }

  validateSection(activeTab.value, valid => {
    if (!valid || !isValid) {
      SET_ERRORS([activeTab.value])
      const invalidFields = refs[activeTab.value + 'Validation'].value.fields.filter(field => field.validateState !== 'success').map(field => field.prop)

      SET_INVALID_ELEMENTS(invalidFields)
    } else {
      SET_ERRORS([])
      SET_INVALID_ELEMENTS([])
    }
  })
}

const getFormData = (method = '', isCreate = false) => {
  const payload = {
    ...data.value,
    icon: getIcon(),
    services: getValidValues('services')
  }

  if (method === 'validate') {
    delete payload.sensors
  }

  if (activeTab.value === sections.SENSORS || isCreate) payload.sensors = getValidValues('sensors')

  return payload
}

const handleBack = () => {
  emits('onBack')
}

const editObject = async () => {
  try {
    loading.value = true

    await UPDATE_UNIT(getFormData('', true))

    emits('onSubmit')
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}
const createObject = async () => {
  try {
    loading.value = true
    await CREATE_UNIT(getFormData('', true))

    emits('onSubmit')
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const callSubmit = async () => {
  modalType.value === 'create' ? await createObject() : await editObject()
}

const submitObject = async () => {
  SET_IS_SUBMIT(true)
  await validateSectionHandler()
  if (!errors.value.length) await callSubmit()
  SET_IS_SUBMIT(false)
}
const saveObject = () => {
  submitObject()
}
const onTabChange = async tab => {
  try {
    isCurrent.value = false

    SET_ACTIVE_OBJECT(data.value)

    await VALIDATE_UNIT({
      formData: getFormData('validate'),
      activeTab: activeTab.value
    }).then(() => {
      const switchedTab = tab.charAt(0).toUpperCase() + tab.slice(1)
      const component = defineAsyncComponent(() => import(`../Objects/Sections/${switchedTab}/${switchedTab}.vue`))

      activeTab.value = tab
      activeComponent.value = component
    })
  } catch (error) {
    isCurrent.value = true
    SET_ERRORS([activeTab.value])
  }
}

const getUnitCouplings = date => {
  const payload = {
    fields: ['trailer.name'],
    unitId: activeObject.value.id,
    date_from: date ? date[0] : '',
    date_to: date ? date[1] : ''
  }

  unitTrailersApi.getObjectCouplings(
    payload,
    response => {
      SET_COUPLINGS_ACTIVE_OBJECT(response.data.list)
    },
    error => {
      instance.proxy.$showError(error.response.data.message)
    }
  )
}

const getUnitShifts = date => {
  const payload = {
    unit: activeObject.value.id,
    date_from: date ? date[0] : '',
    date_to: date ? date[1] : ''
  }

  unitDriversApi.getUnitDriversFilter(
    payload,
    response => {
      const shifts = response.data.list.map(shift => {
        const driver = userById.value(shift.user.id)

        return {
          ...shift,
          user: driver || { id: '' }
        }
      })

      SET_SHIFTS_ACTIVE_OBJECT(shifts)
    },
    error => {
      instance.proxy.$showError(error.response.data.message)
    }
  )
}

const getDataByDate = date => {
  switch (activeTab.value) {
    case 'couplings':
      return getUnitCouplings(date)
    case 'shifts':
      return getUnitShifts(date)
    default:
  }
}

const handleShowHistory = () => {
  if (modalType.value === 'create') return
  emits('onShowHistory', { objects: 'units', objectId: activeObject.value.id })
}

watch(activeObject, val => {
  data.value = val
})

onBeforeMount(() => {
  if (modalType.value === 'edit') {
    data.value = {
      ...activeObject.value,
      phonenumber: parseInt(activeObject.value.phonenumber),
      phonenumber2: parseInt(activeObject.value.phonenumber2)
    }
  } else {
    SET_ACTIVE_OBJECT(data.value)
  }

  if (isArchiveEdit.value) {
    activeTab.value = sections.SENSORS
    onTabChange(sections.SENSORS)
  }
})
</script>
