<template>
  <div class="object-sensors__details__table">
    <div
      class="object-sensors__details__table__header flex items-center justify-between pr-four-tenths pl-3"
    >
      <span
        v-for="col in headers"
        :key="col.id"
        class="w-1/2 mainTitleColor flex items-center font-bold"
      >
        {{ col.text }}
        <SortIcon
          v-if="col.value === 'values'"
          :order-sort="orderSort.values"
          class="ml-1 cursor-pointer ml-2"
          @click.native="toggleSortOrder(col.value)"
        />
      </span>
      <el-popover
        v-if="isFirstTable"
        v-model="isClearPopover"
        placement="bottom"
        trigger="click"
        popper-class="sizeScreenReport"
        :disabled="isDisabled"
      >
        <div class="bg-white rounded-md p-1 border-solid border-lightGrey-300">
          <el-button
            class="border-0 bg-red-200 text-white font-semibold cursor-pointer"
            @click="clearTable"
            >{{
              $t('new_units.objects.fields.sensors.fields.parameters.clear')
            }}
          </el-button>
        </div>
        <RedCrossIcon slot="reference" class="cursor-pointer" />
      </el-popover>
    </div>
    <div
      v-for="(item, itemIdx) in props.fields"
      :key="`${itemIdx}sortedFields`"
      class="object-sensors__details__table__content flex items-center justify-between"
      :class="{ error: notValidRows.includes(itemIdx) }"
    >
      <div v-for="(value, key) in item" :key="`${key + 1}item`" class="w-full">
        <el-input
          v-if="key === 'x'"
          v-model="item.x"
          :class="[
            { 'same-value': isXValueDuplicate(item.x, itemIdx, props.fields) },
            {
              'not-valid':
                errors.includes(itemIdx) && (key === 'x' || key === 'fromValue')
            }
          ]"
          type="number"
          @input="$emit('onInput', { itemIdx, key, value: $event })"
          @focus="$emit('onFocus', { itemIdx, key })"
          @blur="$emit('onBlur', { itemIdx, key })"
        />
        <el-input
          v-else
          v-model="item.y.value"
          type="text"
          @input="$emit('onInput', { itemIdx, key, value: $event })"
          @focus="$emit('onFocus', { itemIdx, key })"
          @blur="$emit('onBlur', { itemIdx, key })"
        />
      </div>
      <div
        class="object-sensors__details__table__content--remove flex items-center justify-center py-1 px-2 bg-white"
      >
        <BlackCrossIcon
          :class="[
            'cursor-pointer',
            getDisabledRemoveIcon(fields, itemIdx) ? 'remove-disabled' : ''
          ]"
          @click.native="$emit('onDeleteRow', { itemIdx })"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import BlackCrossIcon from '@/components/unitsNew/assets/icons/black-cross.vue'
import RedCrossIcon from '@/components/unitsNew/assets/icons/red-cross.vue'
import SortIcon from '@/components/unitsNew/assets/icons/sort.vue'
import { useI18n } from '@/hooks/useI18n'

const emits = defineEmits(['onClearTable', 'onToggleSort'])

const props = defineProps({
  fields: {
    type: Array,
    default() {
      return []
    }
  },
  notValidRows: {
    type: Array,
    default: () => []
  },
  errors: {
    type: Array,
    default: () => []
  },
  isFirstTable: {
    type: Boolean,
    default: false
  },
  isDisabled: {
    type: Boolean,
    default: false
  },
  orderSort: {
    type: Object,
    default: () => ({})
  }
})

const isClearPopover = ref(false)

const $t = useI18n()

const headers = [
  {
    text: `${$t('new_units.objects.fields.sensors.value')}`,
    value: 'values',
    orderSort: '',
    id: 1
  },
  {
    text: `${$t('new_units.objects.fields.sensors.output_text')}`,
    value: 'outputText',
    orderSort: '',
    id: 2
  }
]

const toggleSortOrder = (col) => {
  emits('onToggleSort', col)
}

const getDisabledRemoveIcon = (table, itemIdx) => {
  return (
    Object.values(table[itemIdx]).some((item) => !item) &&
    table.length - 1 === itemIdx
  )
}

const isXValueDuplicate = (xValue, itemIdx, sortedFields) => {
  for (let i = 0; i < sortedFields.length; i++) {
    if (i !== itemIdx && String(sortedFields[i].x) === xValue) {
      return true
    }
  }
  return false
}

const clearTable = () => {
  emits('onClearTable')
}
</script>
