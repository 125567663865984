<template>
  <el-radio-group
    v-model="activeType"
    class="object-sensors__details__types relative w-1/2"
    @change="$emit('onChange', $event)"
  >
    <el-radio-button
      v-for="tab in typesList"
      :key="tab.label"
      :label="tab.name"
    >
      {{ tab.label }}
    </el-radio-button>
  </el-radio-group>
</template>
<script setup>
import { ref, watch } from 'vue'

import types from '@/enums/newUnits/sensors-types.js'

import { useI18n } from '@/hooks/useI18n'

const $t = useI18n()

const activeType = ref(types.FORMULA)

const props = defineProps({
  activeType: {
    type: String,
    default: ''
  }
})

watch(
  () => props.activeType,
  (value) => {
    activeType.value = value
  },
  { immediate: true }
)

const typesList = [
  {
    label: $t(`new_units.objects.fields.sensors.fields.${types.FORMULA}`),
    name: types.FORMULA
  },
  {
    label: $t(`new_units.objects.fields.sensors.fields.${types.EXPRESSION}`),
    name: types.EXPRESSION
  }
]
</script>
<style lang=""></style>
