<template>
  <div class="flex flex-col gap-4 px-4">
    <div class="relative">
      <span class="input-label">{{
        $t('new_units.objects.fields.sensors.fields.expression')
      }}</span>
      <el-input
        v-model="expressionValue"
        type="textarea"
        rows="3"
        @input="$emit('onInput', expressionValue)"
      />
    </div>
    <SensorsExpressionHelpers @onAdd="joinParams" />
    <SensorsHint />
  </div>
</template>
<script setup>
import { onBeforeMount, ref, watch } from 'vue'

import SensorsHint from './SensorsHint.vue'
import SensorsExpressionHelpers from './SensorsExpressionHelpers.vue'

const emits = defineEmits(['onChange', 'undoChange'])
const expressionValue = ref('')

const props = defineProps({
  expression: {
    type: String,
    default: ''
  },
  isChanged: {
    type: Boolean,
    default: false
  }
})

watch(
  () => props.expression,
  () => {
    expressionValue.value = props.expression
  }
)

watch(
  () => props.isChanged,
  (val) => {
    if (val) expressionValue.value = props.expression

    emits('undoChange')
  }
)

const joinParams = (value) => {
  expressionValue.value += value

  emits('onChange', expressionValue.value)
}

onBeforeMount(() => {
  expressionValue.value = props.expression
})
</script>
