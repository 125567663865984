<template>
  <div class="object-sensors__details__helpers flex gap-3 flex-wrap">
    <div v-for="item in helpers" :key="item.title">
      <el-dropdown
        v-if="item.type === 'dropdown'"
        trigger="click"
        class="bg-lightGrey-200 px-6 py-2 rounded-md"
        @command="addExpression"
      >
        <span class="el-dropdown-link">
          <span
            v-html="item.sign"
            class="object-sensors__details__helpers--code"
          />
          <span
            v-text="item.title"
            class="object-sensors__details__helpers--title"
          />
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" class="!z-30 overflow-auto max-h-200">
          <el-dropdown-item
            v-for="option in item.options"
            :key="option"
            :command="option"
          >
            {{ option }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div
        v-else
        class="object-sensors__details__helpers--chip curiousBlue bg-lightGrey-200 py-2 px-6 rounded-md cursor-pointer"
        @click="addExpression(item.value)"
      >
        <span v-html="item.sign" />
        <span>{{ item.title }}</span>
      </div>
    </div>
    <div class="flex gap-2">
      <div
        v-for="item in actions"
        :key="item.value"
        class="object-sensors__details__helpers--chip curiousBlue bg-lightGrey-200 py-2 px-4 rounded-md cursor-pointer"
        @click="addExpression(item.value)"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from '@/hooks/useI18n'

import { createVuexHelpers } from 'vue2-helpers'

const { useGetters } = createVuexHelpers()

const { activeObject } = useGetters('units', ['activeObject'])
const { getParamsByKey } = useGetters('dictionary', ['getParamsByKey'])

const $t = useI18n()
const emits = defineEmits('onAdd')

const helpers = [
  {
    type: 'dropdown',
    sign: '&#35;',
    title: $t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.param'
    ),
    options: getParamsByKey
      .value(activeObject.value.terminal_type.key)
      .map((item) => `#${item.value}`)
  },
  {
    type: 'dropdown',
    sign: '&#35;',
    title: $t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.param_minus_1'
    ),
    options: getParamsByKey
      .value(activeObject.value.terminal_type.key)
      .map((item) => `#${item.value}[-1]`)
  },
  {
    type: 'dropdown',
    sign: '&#36;',
    title: `{${$t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.sensor_name'
    )}}`,
    options: activeObject.value.sensors.map((item) => '${' + item.name + '}')
  },
  {
    type: 'chip',
    sign: '&#36;',
    title: $t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.param1'
    ),
    value: '$param1'
  },
  {
    type: 'chip',
    sign: '&#36;',
    title: $t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.param1_minus_1'
    ),
    value: '$param1[-1]'
  },
  {
    type: 'chip',
    sign: '&#36;',
    title: $t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.param2'
    ),
    value: '$param2'
  },
  {
    type: 'chip',
    sign: '&#36;',
    title: $t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.param2_minus_1'
    ),
    value: '$param2[-1]'
  }
]

const actions = [
  {
    type: 'action',
    title: '+',
    value: '+'
  },
  {
    type: 'action',
    title: '-',
    value: '-'
  },
  {
    type: 'action',
    title: '*',
    value: '*'
  },
  {
    type: 'action',
    title: '/',
    value: '/'
  },
  {
    type: 'action',
    title: '(',
    value: '('
  },
  {
    type: 'action',
    title: ')',
    value: ')'
  },
  {
    type: 'action',
    title: `^(${$t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.degree'
    )})`,
    value: '^'
  },
  {
    type: 'action',
    title: ':',
    value: ':'
  }
]

// const handleCommand = (e) => {
// 	console.log(e);
// }

const addExpression = (value) => {
  emits('onAdd', value)
}
</script>
