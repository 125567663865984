<template>
  <div v-if="item" :class="['objects-block', { disabled: +item.sensors_count === 0 || !item.sensors_count }]">
    <div class="object" @click="openItem">
      <span class="object-name">{{ item.name }}</span>
      <div v-if="+item.sensors_count" :class="['object-collaps', { openSensors: openSensors }]">
        <svg
          width="7"
          height="10"
          viewBox="0 0 7 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5 5L1 9"
            stroke="#3A7CCA"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
    <div class="sensors" v-if="openSensors">
      <div class="sensors-loading" v-if="loading">
        <LoaderLine />
      </div>
      <div
        class="sensors-items"
        v-else-if="item.sensors && item.sensors.length"
        v-for="(sensor, idx) in item.sensors"
        :key="idx"
      >
        <el-checkbox
          :key="idx"
          :name="item.name"
          v-model="sensor.checked"
          :label="sensor.name"
        />
      </div>
      <div class="sensors-nodata" v-else>{{ $t('objectsSensors.noData') }}</div>
    </div>
  </div>
</template>

<script>
import LoaderLine from '@/components/unitsNew/components/modalSensors/components/loaderLine'

export default {
  components: {
    LoaderLine
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      openSensors: false
    }
  },

  methods: {
    openItem() {
      if(+this.item.sensors_count) {
        this.openSensors = !this.openSensors
      }
      if(this.openSensors) {
        this.$emit('openModal', this.item)
        this.$emit('selectSensor', this.item)
      }

    },
  },
}
</script>

<style scoped lang="scss">
.objects-block {
  .object {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #292929;
    padding: 10px 20px;
    border-top: 1px solid #edeff3;

    &-collaps {
      background: #edeff3;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .sensors {
    padding: 0 0 14px 20px;

    &-nodata {
      text-align: center;
    }

    &-loading {
      width: 60px;
      margin: 0 auto;
    }
  }
}
.openSensors {
  transform: rotate(90deg);
}
.disabled {
  background: rgba(128, 128, 128, 0.08);

  .object {
    cursor: default;
    &-name {
      color: grey;
    }
  }
}
</style>
