<template>
  <el-collapse
    class="object-sensors__details__parameters mt-6"
    @change="openParam"
  >
    <el-collapse-item
      v-for="(item, idx) in parameters"
      :key="idx"
      :name="idx + 1"
    >
      <template slot="title">
        <div class="flex justify-between items-center w-full">
          <span
            v-if="!item.isSensorCalibration"
            :class="{
              'text-red-200': invalidElements.includes(
                `param${idx + 1}.param_key`
              )
            }"
          >
            {{
              $t(
                `new_units.objects.fields.sensors.fields.parameters.param_title_${
                  idx + 1
                }`
              )
            }}
            <span
              :class="[
                invalidElements.includes(`param${idx + 1}.param_key`)
                  ? 'text-red-200'
                  : 'text-grey-200'
              ]"
            >
              ({{
                $t(
                  `new_units.objects.fields.sensors.fields.parameters.param_title_s_${
                    idx + 1
                  }`
                )
              }}
              <span v-if="item.param_key">&ndash; {{ item.param_key }}</span
              >)
            </span>
          </span>
          <span v-else>
            {{
              $t(
                'new_units.objects.fields.sensors.fields.parameters.sensor_calibration'
              )
            }}
            <span
              v-if="item.calibrationXYlist[0] && item.calibrationXYlist[0].x"
              class="text-grey-200"
            >
              ({{
                $t('new_units.objects.fields.sensors.fields.parameters.exist')
              }})
            </span>
          </span>
          <div
            class="flex justify-center items-center w-5 h-5 rounded-full bg-lightGrey-200"
          >
            <ArrowDownIcon
              :class="[
                'arrow-icon',
                'transform',
                isOpenedIdx.includes(idx + 1) ? 'rotate-90' : 'rotate-0'
              ]"
            />
          </div>
        </div>
      </template>
      <div class="flex flex-col gap-4 mt-3">
        <div v-if="!item.isSensorCalibration" class="relative">
          <span class="input-label">{{
            $t(
              `new_units.objects.fields.sensors.fields.parameters.param_option_${
                idx + 1
              }`
            )
          }}</span>
          <el-form-item :rules="rule[idx]" :prop="`param${idx + 1}.param_key`">
            <el-select
              v-model="item.param_key"
              :placeholder="$t('new_units.placeholder.select')"
              :popper-append-to-body="false"
              filterable
              class="w-full"
            >
              <el-option
                v-for="option in getParamsByKey(activeObject.terminal_type.key)"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="flex gap-3">
          <div class="relative w-1/2">
            <span class="input-label">{{
              $t(
                'new_units.objects.fields.sensors.fields.parameters.lower_bound_x'
              )
            }}</span>
            <el-input
              v-model="item.min_valid"
              @input="setValid('min', idx, $event)"
            />
          </div>
          <div class="relative w-1/2">
            <span class="input-label">{{
              $t(
                'new_units.objects.fields.sensors.fields.parameters.upper_bound_x'
              )
            }}</span>
            <el-input
              v-model="item.max_valid"
              @input="setValid('max', idx, $event)"
            />
          </div>
        </div>
        <div class="flex items-center justify-between">
          <label :for="`csv-import-${idx}`">
            <input
              type="file"
              :id="`csv-import-${idx}`"
              accept=".csv"
              class="hidden"
              @change="importCSV(idx)"
            />
            <span
              class="curiousBlue text-sizeThirteen font-bold cursor-pointer"
            >
              {{
                $t(
                  'new_units.objects.fields.sensors.fields.parameters.download_from_csv'
                )
              }}
            </span>
          </label>
          <div class="flex items-center gap-2 cursor-pointer">
            <RelationIcon />
            <span class="greyToneSecond text-sizeTen">{{
              $t(
                'new_units.objects.fields.sensors.fields.parameters.related_tables'
              )
            }}</span>
          </div>
        </div>
        <div class="flex gap-4">
          <SensorsTable
            :headers="headersFirst"
            :coefficients="item.calibrationXYlist"
            :notValidRows="notValidRows"
            :errors="errorsValuesIndexXY"
            :is-disabled="isClearTableDisabled(idx)"
            is-first-table
            class="w-1/3"
            @onAddRow="addRow(item.calibrationXYlist)"
            @onDeleteRow="
              deleteRow(
                $event,
                item.calibrationXYlist,
                idx,
                'calibrationABlist'
              )
            "
            @onInput="
              recalculateValues(
                $event,
                item.calibrationXYlist,
                idx,
                'calibrationABlist'
              )
            "
            @onFocus="checkFocused($event, idx, 'calibrationXYlist')"
            @onBlur="checkBlured(idx, 'calibrationXYlist')"
            @onClearTable="clearTable(idx)"
          />
          <SensorsTable
            :headers="headersSecond"
            :coefficients="item.calibrationABlist"
            :notValidRows="notValidRows"
            :errors="errorsValuesIndexAB"
            class="w-2/3"
            @onAddRow="addRow(item.calibrationABlist, 'second')"
            @onDeleteRow="
              deleteRow(
                $event,
                item.calibrationABlist,
                idx,
                'calibrationXYlist'
              )
            "
            @onInput="
              recalculateValues(
                $event,
                item.calibrationABlist,
                idx,
                'calibrationXYlist'
              )
            "
            @onFocus="checkFocused($event, idx, 'calibrationABlist')"
            @onBlur="checkBlured(idx, 'calibrationABlist')"
          />
        </div>
        <div class="flex justify-between">
          <span
            class="curiousBlue font-bold cursor-pointer"
            @click="downloadCSV(idx)"
            >{{
              $t(
                'new_units.objects.fields.sensors.fields.parameters.download_as_csv'
              )
            }}</span
          >
          <span
            v-if="item.isGraphShown"
            class="curiousBlue font-bold cursor-pointer"
            @click="toggleGraph(idx)"
            >{{
              $t(
                'new_units.objects.fields.sensors.fields.parameters.hide_graph'
              )
            }}</span
          >
          <span
            v-else
            class="curiousBlue font-bold cursor-pointer"
            @click="toggleGraph(idx)"
            >{{
              $t(
                'new_units.objects.fields.sensors.fields.parameters.show_graph'
              )
            }}</span
          >
        </div>
        <SensorsGraphic
          v-if="item.isGraphShown"
          :values="item.calibrationXYlist"
          @onDownloadCSV="downloadCSV(idx)"
        />
        <div v-if="item.isSensorCalibration" class="flex flex-col gap-5">
          <div class="flex justify-between">
            <div class="text">
              <span class="demonstration font-bold mainTitleColor">
                {{ $t('new_units.objects.fields.sensors.value_to_sensor') }}
              </span>
              <UnitsSwitch
                :value="item.is_string_value"
                class="object-sensors__details__table__switcher ml-1"
                @onChange="$emit('changeIsTextConverter', $event)"
              />
            </div>
            <el-tooltip
              :content="
                $t('new_units.objects.fields.sensors.value_to_sensor_text')
              "
              :visible-arrow="false"
              popper-class="tooltip"
              placement="bottom-end"
            >
              <QuestionIcon />
            </el-tooltip>
          </div>
          <div v-if="item.is_string_value" class="block">
            <SensorsTextFields
              :fields="sortedStringConverters"
              :order-sort="orderSort"
              @onInput="recalculateStringConverters"
              @onDeleteRow="deleteTextFieldsRow"
              @onToggleSort="toggleSortOrder"
            />
          </div>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>
<script setup>
import { ref, watch, getCurrentInstance, computed, onMounted } from 'vue'

import { v4 as uuidv4 } from 'uuid'

import SensorsTable from './SensorsTable.vue'
import SensorsGraphic from './SensorsGraphic.vue'
import UnitsSwitch from '@/components/unitsNew/components/UnitsSwitch.vue'
import SensorsTextFields from '@/components/unitsNew/components/Objects/Sections/Sensors/SensorsTextFields.vue'

import RelationIcon from '@/components/unitsNew/assets/icons/relation.vue'
import ArrowDownIcon from '@/components/unitsNew/assets/icons/arrow-down.vue'
import QuestionIcon from '@/components/unitsNew/assets/icons/question-icon'

import { createVuexHelpers } from 'vue2-helpers'

import { useI18n } from '@/hooks/useI18n'

const instance = getCurrentInstance()

const { useGetters, useMutations } = createVuexHelpers()

const $t = useI18n()

const emits = defineEmits([
  'onChangeSensorCalibration',
  'onAddRowSensorCalibration',
  'onGraphToggle'
])

const props = defineProps({
  parameters: {
    type: Array,
    default: () => []
  },
  rule: {
    type: Array,
    default: () => []
  },
  sensorGraphic: {
    type: Boolean,
    default: false
  },
  sensorId: {
    type: String,
    default: ''
  },
  stringConverters: {
    type: Array,
    default: () => []
  }
})

const { activeObject, invalidElements } = useGetters('units', [
  'activeObject',
  'invalidElements'
])
const { getParamsByKey } = useGetters('dictionary', ['getParamsByKey'])

const {
  SET_ERRORS,
  SET_SENSORS_ERRORS,
  SET_SENSOR_PARAM_CALIBRATION,
  SET_SENSOR_MAX_MIN_BORDER
} = useMutations('units', [
  'SET_ERRORS',
  'SET_SENSORS_ERRORS',
  'SET_SENSOR_PARAM_CALIBRATION',
  'SET_SENSOR_MAX_MIN_BORDER'
])

const isValidTableValue = ref(true)
const isOpenedIdx = ref([])

const headersFirst = ['X:', 'Y:']
const headersSecond = [
  `Х${$t(
    'new_units.objects.fields.sensors.fields.parameters.lower_bound_short'
  )}`,
  'А',
  'Б'
]

const errorsValuesIndexXY = ref([])
const errorsValuesIndexAB = ref([])
const notValidRows = ref([])
const delArray = [',', ';', ' ']

const focusedRow = ref({
  rowIdx: null,
  paramIdx: null,
  table: null
})

const addRow = (coefficients, table = 'first', isSensorCalibration = false) => {
  if (!isValidTableValue.value) {
    return instance.proxy.$showError(
      $t('new_units.objects.fields.sensors.fields.errors.invalid_value')
    )
  }

  if (table === 'first') {
    const item = {
      x: '',
      y: '',
      id: uuidv4()
    }

    isSensorCalibration
      ? emits('onAddRowSensorCalibration', {
          table: 'calibrationXYlist',
          tableValues: item
        })
      : coefficients.push(item)
  } else {
    const item = {
      fromValue: '',
      a: '',
      b: '',
      id: uuidv4()
    }

    isSensorCalibration
      ? emits('onAddRowSensorCalibration', {
          table: 'calibrationABlist',
          tableValues: item
        })
      : coefficients.push(item)
  }
}

const setValid = (type, idx, value) => {
  if (idx === 2) {
    SET_SENSOR_MAX_MIN_BORDER({ type, id: props.sensorId, value })
  }
}

const deleteRow = (row, coefficients, paramIdx, table) => {
  if (row.itemIdx === coefficients.length - 1) {
    return
  }

  const currentTable =
    table === 'calibrationABlist' ? 'calibrationXYlist' : 'calibrationABlist'

  const isSensorCalibration = paramIdx === props.parameters.length - 1

  if (isSensorCalibration) {
    emits('onDeleteRowSensorCalibration', {
      table: currentTable,
      idx: row.itemIdx
    })
  } else {
    coefficients.splice(row.itemIdx, 1)
  }

  if (props.parameters[paramIdx][table][row.itemIdx]) {
    if (isSensorCalibration) {
      emits('onDeleteRowSensorCalibration', { table, idx: row.itemIdx })
    } else {
      props.parameters[paramIdx][table].splice(row.itemIdx, 1)
    }

    errorsValuesIndexXY.value = errorsValuesIndexXY.value.filter(
      (item) => item !== row.itemIdx
    )
    errorsValuesIndexAB.value = errorsValuesIndexAB.value.filter(
      (item) => item !== row.itemIdx
    )

    if (errorsValuesIndexXY.value.length === 1) errorsValuesIndexXY.value = []
    if (errorsValuesIndexAB.value.length === 1) errorsValuesIndexAB.value = []
  }

  recalculateValues(
    { key: '', itemIdx: row.itemIdx - 1 },
    coefficients,
    paramIdx,
    table,
    true
  )
}

const toggleGraph = (idx) => {
  emits('onGraphToggle', idx)
}

const importCSV = (idx) => {
  const { files } = document.querySelector(`#csv-import-${idx}`)

  if (files.length > 0) {
    const file = files[0]
    const reader = new FileReader()

    // Read file as string
    reader.readAsText(file)

    // Load event
    reader.onload = function (event) {
      const csvData = event.target.result.split('\n')

      let separator = ''

      delArray.forEach((delimiter) => {
        const isDelimiter = csvData[0].split('').includes(delimiter)

        if (isDelimiter) {
          separator = delimiter
        }
      })

      let content = csvData.map((item) => {
        let x = ''
        let y = ''

        if (item) {
          x = +item.split(separator)[0]
          y = +item.split(separator)[1]
        }

        return { x: x || 0, y: y || 0 }
      })

      content = content.filter((item) => item.x || item.y)

      props.parameters[idx].calibrationXYlist = content
      props.parameters[idx].calibrationABlist = recalculateABfromXY(content)

      addEmptyRows(null, idx, 'calibrationXYlist')
      addEmptyRows(null, idx, 'calibrationABlist')
    }
  }
}

const downloadCSV = (idx) => {
  let csv = ''
  const values = props.parameters[idx].calibrationXYlist.slice()
  values.splice(-1)
  values.forEach((row) => {
    csv += Object.values(row).join(';')
    csv += '\n'
  })

  const anchor = document.createElement('a')
  anchor.href = `data:text/csvcharset=utf-8,${encodeURIComponent(csv)}`
  anchor.target = '_blank'
  anchor.download = 'X_Y.csv'

  anchor.click()
}

const openParam = (idx) => {
  isOpenedIdx.value = idx
}

const addEmptyRows = (nextRow, paramIdx, table) => {
  if (!nextRow) {
    const type = table === 'calibrationXYlist' ? 'first' : 'second'

    addRow(
      props.parameters[paramIdx][table],
      type,
      paramIdx === props.parameters.length - 1
    )
  }
}

const validateRow = () => {
  let row = null

  if (focusedRow.value.table) {
    row =
      props.parameters[focusedRow.value.paramIdx][focusedRow.value.table][
        focusedRow.value.rowIdx
      ]
  }

  if (row) {
    const isValid = Object.values(row).every((item) => item.toString())
    const lastIndex =
      props.parameters[focusedRow.value.paramIdx][focusedRow.value.table]
        .length - 1

    if (
      !isValid &&
      !notValidRows.value.includes(focusedRow.value.rowIdx) &&
      focusedRow.value.rowIdx !== lastIndex
    ) {
      notValidRows.value.push(focusedRow.value.rowIdx)
    }

    if (isValid) {
      notValidRows.value = notValidRows.value.filter(
        (idx) => idx !== focusedRow.value.rowIdx
      )
    }
  }
}

const recalculateValues = (
  row,
  formData,
  paramIdx,
  table,
  isDelete = false
) => {
  const currentTable =
    table === 'calibrationABlist' ? 'calibrationXYlist' : 'calibrationABlist'
  const param = paramIdx === 0 ? 'param1' : 'param2'

  if (row.key) {
    const isEmptyValue = Object.values(formData[row.itemIdx]).every(
      (item) => !item.toString()
    )

    const isBeforeLastRow =
      props.parameters[paramIdx][table].length - 2 === row.itemIdx
    if (isBeforeLastRow && isEmptyValue) {
      props.parameters[paramIdx][currentTable].splice(-1)
      props.parameters[paramIdx][table].splice(-1)

      return
    }
  }

  if (formData[row.itemIdx] || isDelete) {
    let isFilled = true

    if (formData[row.itemIdx]) {
      isFilled = Object.values(formData[row.itemIdx]).every((item) =>
        item.toString()
      )
    }

    if (isFilled || isDelete) {
      let tableValues = []

      const calculationValues = formData.filter((item) => {
        if (Object.values(item).every((item) => item.toString())) {
          return item
        }
      })

      tableValues =
        table === 'calibrationABlist'
          ? recalculateABfromXY(calculationValues)
          : recalculateXYfromAB(calculationValues)

      if (tableValues) {
        if (paramIdx === props.parameters.length - 1) {
          emits('onChangeSensorCalibration', { table, tableValues })
        } else {
          props.parameters[paramIdx][table] = tableValues
          SET_SENSOR_PARAM_CALIBRATION({
            table,
            values: tableValues,
            id: props.sensorId,
            param
          })
          SET_SENSOR_PARAM_CALIBRATION({
            table: currentTable,
            values: calculationValues,
            id: props.sensorId,
            param
          })
        }

        addEmptyRows(null, paramIdx, table)
      }

      addEmptyRows(
        props.parameters[paramIdx][currentTable][row.itemIdx + 1],
        paramIdx,
        currentTable
      )
    } else if (isDelete)
      addEmptyRows(
        props.parameters[paramIdx][table][row.itemIdx + 1],
        paramIdx,
        table
      )
  } else {
    SET_SENSOR_PARAM_CALIBRATION({
      table: 'calibrationABlist',
      values: {
        fromValue: '',
        a: '',
        b: ''
      },
      id: props.sensorId,
      param
    })
    SET_SENSOR_PARAM_CALIBRATION({
      table: 'calibrationXYlist',
      values: {
        x: '',
        y: ''
      },
      id: props.sensorId,
      param
    })
  }

  isClearTableDisabled(paramIdx)
}

function recalculateXYfromAB(calibrationAB) {
  const lower_bound_x = []
  errorsValuesIndexAB.value = []
  SET_ERRORS([])

  const result = calibrationAB.map((item, idx) => {
    if (!lower_bound_x.includes(item.fromValue)) {
      lower_bound_x.push(item.fromValue)
    } else {
      const duplicatedValueIndex = lower_bound_x.indexOf(item.fromValue)

      errorsValuesIndexAB.value.push(idx, duplicatedValueIndex)
      SET_ERRORS(['sensors'])

      return instance.proxy.$showError(
        $t(
          'new_units.objects.fields.sensors.fields.errors.duplicate_lower_bound'
        )
      )
    }

    const x = validTableValue(item.fromValue)
    const y = validTableValue(item.fromValue * item.a + +item.b)
    const id = item.id || uuidv4()

    return {
      x,
      y,
      id
    }
  })

  return result.filter((item) => item)
}

function recalculateABfromXY(xy_list) {
  errorsValuesIndexXY.value = []
  SET_ERRORS([])

  const ab_list = []

  if (xy_list.length === 0) return ab_list

  if (xy_list.length > 1) {
    let last_a = 0
    let last_b = 0

    for (let i = 1; i < xy_list.length; i++) {
      if (!errorsValuesIndexXY.value.includes(i)) {
        const currentEl = xy_list[i]
        const prevEl = xy_list[i - 1]

        const cal_ab = {}

        if (Math.abs(currentEl.x - prevEl.x) < 1e-8) {
          console.log('Duplicate')
          errorsValuesIndexXY.value.push(i, i - 1)
          SET_ERRORS(['sensors'])

          return instance.proxy.$showError(
            $t('new_units.objects.fields.sensors.fields.errors.duplicate_x')
          )
        }

        last_a = (currentEl.y - prevEl.y) / (currentEl.x - prevEl.x)
        last_b = prevEl.y - prevEl.x * last_a

        cal_ab.fromValue = validTableValue(prevEl.x)
        cal_ab.a = validTableValue(last_a)
        cal_ab.b = validTableValue(last_b)

        ab_list.push(cal_ab)
      }
    }

    const cal_ab = {}
    const last_XY = xy_list[xy_list.length - 1]
    cal_ab.fromValue = validTableValue(last_XY.x)
    cal_ab.a = validTableValue(last_a)
    cal_ab.b = validTableValue(last_b)
    cal_ab.id = uuidv4()
    ab_list.push(cal_ab)
  } else {
    const el = xy_list[0]
    ab_list.push({
      fromValue: validTableValue(el.x),
      a: 0,
      b: validTableValue(el.y),
      id: el.id || uuidv4()
    })
  }

  return ab_list
}

const validTableValue = (num) => {
  if (num % 1 === 0) return num

  const countAfterDot = num.toString().split('.')[1].length

  if (countAfterDot <= 8) return num

  return parseFloat(num.toFixed(8))
}

const checkFocused = (row, paramIdx, table) => {
  validateRow()
  focusedRow.value.rowIdx = row.itemIdx
  focusedRow.value.paramIdx = paramIdx
  focusedRow.value.table = table
}

const checkingNewRow = () => {
  props.parameters.forEach((item, idx) => {
    const isSensorCalibration = idx === props.parameters.length - 1
    if (!item.calibrationXYlist.length) {
      addRow(item.calibrationXYlist, 'first', isSensorCalibration)
      addRow(item.calibrationABlist, 'second', isSensorCalibration)

      return
    }

    const lastIdx = item.calibrationXYlist.length - 1

    if (
      item.calibrationXYlist[lastIdx].x.toString() &&
      item.calibrationXYlist[lastIdx].y.toString()
    ) {
      addRow(item.calibrationXYlist, 'first', isSensorCalibration)
      addRow(item.calibrationABlist, 'second', isSensorCalibration)
    }
  })
}

const checkBlured = (paramIdx, table) => {
  const xyTable = props.parameters[paramIdx].calibrationXYlist
  const abTable = props.parameters[paramIdx].calibrationABlist
  const lastXYRow = xyTable[xyTable.length - 1]
  const lastABRow = abTable[abTable.length - 1]

  const filledXYValues = props.parameters[paramIdx].calibrationXYlist.filter(
    (item) => {
      return item.x.toString() || item.y.toString()
    }
  )
  const filledABValues = props.parameters[paramIdx].calibrationABlist.filter(
    (item) => {
      return item.a.toString() || item.b.toString() || item.fromValue.toString()
    }
  )

  const emptyXYValues = props.parameters[paramIdx].calibrationXYlist.filter(
    (item) => checkEmptyValues('calibrationXYlist', item)
  )
  const emptyABValues = props.parameters[paramIdx].calibrationABlist.filter(
    (item) => checkEmptyValues('calibrationABlist', item)
  )

  if (checkEmptyValues('calibrationXYlist', lastXYRow)) {
    filledXYValues.sort((a, b) => a.x - b.x)
    props.parameters[paramIdx].calibrationXYlist = [
      ...filledXYValues,
      ...emptyXYValues
    ]
  }

  if (checkEmptyValues('calibrationABlist', lastABRow)) {
    filledABValues.sort((a, b) => a.fromValue - b.fromValue)
    props.parameters[paramIdx].calibrationABlist = [
      ...filledABValues,
      ...emptyABValues
    ]
  }
}

const checkEmptyValues = (table, item) => {
  if (table === 'calibrationXYlist') {
    return !item.x.toString() && !item.y.toString()
  }
  return !item.a.toString() && !item.b.toString() && !item.fromValue.toString()
}

const clearTable = (paramIdx) => {
  const isSensorCalibration = paramIdx === props.parameters.length - 1
  if (isSensorCalibration) {
    emits('onChangeSensorCalibration', {
      table: 'calibrationABlist',
      tableValues: []
    })
    emits('onChangeSensorCalibration', {
      table: 'calibrationXYlist',
      tableValues: []
    })
  } else {
    const param = paramIdx === 0 ? 'param1' : 'param2'
    SET_SENSOR_PARAM_CALIBRATION({
      table: 'calibrationABlist',
      values: [],
      id: props.sensorId,
      param
    })
    SET_SENSOR_PARAM_CALIBRATION({
      table: 'calibrationXYlist',
      values: [],
      id: props.sensorId,
      param
    })
  }

  addRow(
    props.parameters[paramIdx].calibrationXYlist,
    'first',
    isSensorCalibration
  )
  addRow(
    props.parameters[paramIdx].calibrationABlist,
    'second',
    isSensorCalibration
  )

  isClearTableDisabled(paramIdx)
}

const isClearTableDisabled = (paramIdx) => {
  const abListLength = props.parameters[paramIdx].calibrationABlist.length
  const xyListLength = props.parameters[paramIdx].calibrationXYlist.length

  if (!abListLength || !xyListLength || (abListLength > 1 && xyListLength > 1))
    return false

  const hasValueAB = Object.values(
    props.parameters[paramIdx].calibrationABlist[0]
  ).some((item, idx, arr) => {
    if (idx !== arr.length - 1) {
      return item.toString()
    }
  })

  const hasValueXY = Object.values(
    props.parameters[paramIdx].calibrationXYlist[0]
  ).some((item, idx, arr) => {
    if (idx !== arr.length - 1) {
      return item.toString()
    }
  })

  if (hasValueAB || hasValueXY) {
    return false
  }
  return true
}

watch(
  () => props.parameters,
  () => {
    checkingNewRow()
  },
  { immediate: true }
)

watch(
  activeObject,
  () => {
    checkingNewRow()
  },
  { immediate: true }
)

const tableError = computed(() => {
  return (
    errorsValuesIndexAB.value.length ||
    errorsValuesIndexXY.value.length ||
    notValidRows.value.length
  )
})

watch(tableError, (val) => {
  SET_SENSORS_ERRORS(!!val)
})

watch(
  () => props.stringConverters,
  () => {
    if (!isEmptyField(props.stringConverters)) {
      props.stringConverters.push({ x: '', y: { value: '' } })
    }
  }
)

const isSortChanged = ref(false)

const orderSort = ref({
  values: '',
  outputText: ''
})

const isEmptyField = (array) => {
  return array.some((item) => {
    return item.x === '' || item.y.value === ''
  })
}

const recalculateStringConverters = (value) => {
  isSortChanged.value = false

  if (value.key === 'x') {
    props.stringConverters[value.itemIdx][value.key] = value.value
  } else {
    props.stringConverters[value.itemIdx][value.key].value = value.value
    if (!isEmptyField(props.stringConverters)) {
      props.stringConverters.push({ x: '', y: { value: '' } })
    }
  }
}

const deleteTextFieldsRow = (value) => {
  if (value.itemIdx === props.stringConverters.length - 1) return
  props.stringConverters.splice(value.itemIdx, 1)
}

const toggleSortOrder = (col) => {
  isSortChanged.value = true

  if (orderSort.value[col] === 'desc') {
    orderSort.value[col] = 'asc'
  } else if (orderSort.value[col] === 'asc') {
    orderSort.value[col] = ''
  } else {
    orderSort.value[col] = 'desc'
  }
}

const sortedStringConverters = computed(() => {
  if (!isSortChanged.value) {
    return props.stringConverters
  }

  if (orderSort.value.values === 'desc') {
    return props.stringConverters.sort((a, b) => {
      if (a.x === '') {
        return 1
      }
      return a.x - b.x
    })
  }
  if (orderSort.value.values === 'asc') {
    return props.stringConverters.sort((a, b) => {
      if (a.x === '') {
        return 1
      }
      return b.x - a.x
    })
  }

  return props.stringConverters
})
</script>
