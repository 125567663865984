<template>
  <el-collapse class="object-sensors__details__hint w-full" @change="openHint">
    <el-collapse-item name="1">
      <template slot="title">
        <div
          class="object-sensors__details__hint__header flex items-center justify-between w-full px-2"
          :class="{ isOpen: isOpened }"
        >
          <div class="flex items-center gap-3">
            <NoteInfoIcon />
            <span class="font-bold text-base text-activeTabColor">{{
              $t('new_units.objects.fields.sensors.fields.hint.title')
            }}</span>
          </div>
          <span class="font-semibold text-sm text-activeTabColor">{{
            isOpened
              ? $t('new_units.objects.fields.sensors.fields.hint.hide')
              : $t('new_units.objects.fields.sensors.fields.hint.show')
          }}</span>
        </div>
      </template>
      <div
        v-for="item in helpers"
        :key="item.name"
        class="text-xs text-activeTabColor lowercase"
      >
        <span v-html="item.sign + item.name" />
        <span v-text="item.text" class="font-normal" />
      </div>
    </el-collapse-item>
  </el-collapse>
</template>
<script setup>
import { ref } from 'vue'
import { useI18n } from '@/hooks/useI18n'

import NoteInfoIcon from '@/components/unitsNew/assets/icons/note-info.vue'
const $t = useI18n()

const isOpened = ref(false)

const openHint = () => {
  isOpened.value = !isOpened.value

  console.log(isOpened.value)
}

const helpers = [
  {
    name: $t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.param'
    ),
    sign: '&#35;',
    text: `- ${$t(
      'new_units.objects.fields.sensors.fields.parameters.helpers_description.param'
    )}`
  },
  {
    name: $t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.param_minus_1'
    ),
    sign: '&#35;',
    text: `- ${$t(
      'new_units.objects.fields.sensors.fields.parameters.helpers_description.param_minus_1'
    )}`
  },
  {
    name: $t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.param1'
    ),
    sign: '&#36;',
    text: `- ${$t(
      'new_units.objects.fields.sensors.fields.parameters.helpers_description.param1'
    )}`
  },
  {
    name: $t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.param1_minus_1'
    ),
    sign: '&#36;',
    text: `- ${$t(
      'new_units.objects.fields.sensors.fields.parameters.helpers_description.param1_minus_1'
    )}`
  },
  {
    name: $t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.param2'
    ),
    sign: '&#36;',
    text: `- ${$t(
      'new_units.objects.fields.sensors.fields.parameters.helpers_description.param2'
    )}`
  },
  {
    name: $t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.param2_minus_1'
    ),
    sign: '&#36;',
    text: `- ${$t(
      'new_units.objects.fields.sensors.fields.parameters.helpers_description.param2_minus_1'
    )}`
  },
  {
    name: `{${$t(
      'new_units.objects.fields.sensors.fields.parameters.helpers.sensor_name'
    )}}`,
    sign: '&#36;',
    text: `- ${$t(
      'new_units.objects.fields.sensors.fields.parameters.helpers_description.sensor_name'
    )}`
  },
  {
    name: '',
    sign: '&#34; &#58; &#34;',
    text: `- ${$t(
      'new_units.objects.fields.sensors.fields.parameters.helpers_description.byte_control'
    )}`
  }
]
</script>
