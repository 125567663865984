<template>
  <el-collapse
    class="object-sensors__details__technical__parameters"
    @change="openParam"
  >
    <el-collapse-item>
      <template slot="title">
        <div class="flex justify-between items-center w-full">
          <div class="flex items-center gap-1">
            <span>
              {{
                $t(
                  'new_units.objects.fields.sensors.fields.connected_sensors.title'
                )
              }}
            </span>
            <span class="text-grey-200"> ({{ connectedSensors.length }}) </span>
          </div>
          <div
            class="flex justify-center items-center w-5 h-5 rounded-full bg-lightGrey-200"
          >
            <ArrowDownIcon
              :class="[
                'arrow-icon',
                'transform',
                isOpen ? 'rotate-90' : 'rotate-0'
              ]"
            />
          </div>
        </div>
      </template>
      <ul v-if="connectedSensors.length">
        <li
          v-for="(item, index) in connectedSensors"
          :key="index"
          class="valueRow items-center"
        >
          <label class="labelRow leading-4" for="value">
            {{ item.name }}
          </label>
          <div class="flex items-center gap-4">
            <div
              class="flex items-center gap-1 rounded-20 bg-lightGrey-200 border border-lightGrey-300 p-1 pr-3"
            >
              <CheckCircleIcon />
              <span class="text-sm text-green-100">
                {{
                  $t(
                    'new_units.objects.fields.sensors.fields.connected_sensors.connected'
                  )
                }}
              </span>
            </div>
            <span
              class="text-grey-200 text-xs font-normal cursor-pointer"
              @click="deleteConnectedSensor(item)"
            >
              {{
                $t(
                  'new_units.objects.fields.sensors.fields.connected_sensors.cancel'
                )
              }}
            </span>
          </div>
        </li>
      </ul>
      <div class="add-tech-params px-4">
        <AddTechParamsIcon
          class="'add-params-icon'"
          :class="{
            'add-params-icon--grey':
              connectedSensors.length === dropdownList.length
          }"
          @click.native="openTechFields"
        />
        <span
          :class="[
            'title',
            { 'title--grey': connectedSensors.length === dropdownList.length }
          ]"
          @click="openTechFields"
        >
          {{
            $t(
              'new_units.objects.fields.sensors.fields.connected_sensors.bundle_with_ignition'
            )
          }}
        </span>
      </div>
      <ul v-if="isTechFieldsOpen" class="select-tech-fields">
        <li
          v-for="item in dropdownList"
          :key="item.id"
          class="select-name"
          :class="{
            'text-grey-300 pointer-events-none': isFieldAlreadyAdded(item)
          }"
          @click="addConnectedSensor(item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </el-collapse-item>
  </el-collapse>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'

import ArrowDownIcon from '@/components/unitsNew/assets/icons/arrow-down.vue'
import CheckCircleIcon from '@/components/unitsNew/assets/icons/check-circle_green.vue'
import AddTechParamsIcon from '@/components/unitsNew/assets/icons/add-tech-params.vue'

const { useGetters } = createVuexHelpers()

const { activeObject } = useGetters('units', ['activeObject'])

const emits = defineEmits(['onDeleteConnectedSensor', 'onAddConnectedSensor'])

const props = defineProps({
  fields: {
    type: Array,
    default: () => []
  },
  availableSensors: {
    type: Array,
    default: () => []
  }
})
const $t = useI18n()

const isOpen = ref(false)
const isTechFieldsOpen = ref(false)
const selectedSensors = ref([])

const openParam = () => {
  isOpen.value = !isOpen.value
  isTechFieldsOpen.value = false
}

const openTechFields = () => {
  if (!dropdownList.value.length) return
  isTechFieldsOpen.value = !isTechFieldsOpen.value
}

const addConnectedSensor = (item) => {
  selectedSensors.value.push({ id: item.id })
  emits('onAddConnectedSensor', item.id)
}

const deleteConnectedSensor = (item) => {
  selectedSensors.value = selectedSensors.value.filter(
    (sensor) => sensor.id !== item.id
  )
  emits('onDeleteConnectedSensor', item.id)
}

const isFieldAlreadyAdded = (item) => {
  return selectedSensors.value.some((sensor) => sensor.id === item.id)
}

const isAvailableForBundle = (item) => {
  return (
    !item.isNew &&
    props.availableSensors[0].sensors_types.includes(item.type.key)
  )
}

const dropdownList = computed(() => {
  if (!props.availableSensors.length) return []
  return activeObject.value.sensors.filter(isAvailableForBundle)
})

const connectedSensors = computed(() => {
  if (!selectedSensors.value.length) return []
  return activeObject.value.sensors.filter((item) =>
    selectedSensors.value.map((sensor) => sensor.id).includes(item.id)
  )
})

watch(dropdownList, (val) => {
  if (!val.length) isTechFieldsOpen.value = false
})

watch(
  () => props.fields,
  (val) => {
    selectedSensors.value = val.slice()
  },
  {
    immediate: true
  }
)
</script>
