<template>
  <div class="object-sensors__details__graphic bg-lightGrey-100 rounded-xl p-3">
    <div class="flex items-center justify-between mb-3">
      <div class="flex items-center gap-2">
        <DownloadIcon class="cursor-pointer" />
        <!-- <span
					v-for="ext in filesExtensions"
					:key="ext.value"
					v-text="ext.label"
					class="extension curiousBlue font-bold cursor-pointer"
					@click="downloadCsv(ext.value)"
				/> -->
      </div>
    </div>
    <transition name="fade">
      <div class="shadow">
        <LineChart :chart-data="chartData" :style="graphStyles" />
      </div>
    </transition>
  </div>
</template>
<script setup>
import { computed } from 'vue'

import DownloadIcon from '@/components/unitsNew/assets/icons/download.vue'
import LineChart from '@/utils/LineChart.js'

const props = defineProps({
  values: {
    type: Array,
    default: () => []
  }
})

const filesExtensions = [
  {
    label: 'PNG',
    value: 'png'
  },
  {
    label: 'PDF',
    value: 'pdf'
  },
  {
    label: 'SVG',
    value: 'svg'
  }
]

const graphStyles = {
  width: '450px',
  position: 'relative'
}

const chartData = computed(() => {
  return {
    labels: props.values
      .filter((el) => {
        if (el.x !== '') return el
      })
      .map((el) => el.x),
    datasets: [
      {
        borderColor: '#9E1FB2',
        fill: false,
        backgroundColor: '#F6F7FA',
        data: props.values
          .filter((el) => {
            if (el.y !== '') return el
          })
          .map((el) => el.y),
        lineTension: 0
      }
    ]
  }
})

const downloadCsv = (value) => {
  console.log(value, 'downloadCsv')
}
</script>
