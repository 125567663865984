var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'object-remove-button',
    'flex',
    'items-center',
    'gap-1',
    { active: _vm.deletedCount }
  ]},[_c('span',{class:[
      'object-remove-button__title',
      { 'cursor-pointer': _vm.deletedCount }
    ],on:{"click":function($event){return _vm.$emit('onDelete')}}},[_vm._v(_vm._s(_setup.$t('new_units.delete')))]),_c('div',{staticClass:"object-remove-button__count flex justify-center items-center"},[_vm._v(" "+_vm._s(_setup.props.deletedCount)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }