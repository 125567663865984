<template>
  <div class="object-sensors__details__table">
    <div
      class="object-sensors__details__table__header flex items-center justify-between pr-four-tenths pl-3"
    >
      <span
        v-for="col in headers"
        :key="col"
        class="w-1/2 mainTitleColor font-bold"
        >{{ col }}</span
      >
      <el-popover
        v-if="isFirstTable"
        v-model="isClearPopover"
        placement="bottom"
        trigger="click"
        popper-class="sizeScreenReport"
        :disabled="isDisabled"
      >
        <div class="bg-white rounded-md p-1 border-solid border-lightGrey-300">
          <el-button
            class="border-0 bg-red-200 text-white font-semibold cursor-pointer"
            @click="clearTable"
            >{{
              $t('new_units.objects.fields.sensors.fields.parameters.clear')
            }}
          </el-button>
        </div>
        <RedCrossIcon slot="reference" class="cursor-pointer" />
      </el-popover>
    </div>
    <div
      v-for="(item, itemIdx) in coefficients"
      :key="item.id"
      class="object-sensors__details__table__content flex items-center"
      :class="{ error: notValidRows.includes(itemIdx) }"
    >
      <div v-for="(value, key) in item" :key="item.id + '-' + key">
        <el-input
          v-if="key !== 'id'"
          v-model="coefficients[itemIdx][key]"
          :class="{
            'not-valid':
              errors.includes(itemIdx) && (key === 'x' || key === 'fromValue')
          }"
          type="number"
          @input="$emit('onInput', { itemIdx, key, value: $event })"
          @focus="$emit('onFocus', { itemIdx, key })"
          @blur="$emit('onBlur', { itemIdx, key })"
        />
      </div>
      <div
        class="object-sensors__details__table__content--remove flex items-center justify-center py-1 px-2 bg-white"
      >
        <BlackCrossIcon
          :class="[
            'cursor-pointer',
            { 'remove-disabled': getDisabledRemoveIcon(coefficients, itemIdx) }
          ]"
          @click.native="$emit('onDeleteRow', { itemIdx })"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import BlackCrossIcon from '@/components/unitsNew/assets/icons/black-cross.vue'
import RedCrossIcon from '@/components/unitsNew/assets/icons/red-cross.vue'

const emits = defineEmits(['onClearTable'])

const props = defineProps({
  headers: {
    type: Array,
    default() {
      return []
    }
  },
  coefficients: {
    type: Array,
    default() {
      return []
    }
  },
  notValidRows: {
    type: Array,
    default: () => []
  },
  errors: {
    type: Array,
    default: () => []
  },
  isFirstTable: {
    type: Boolean,
    default: false
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
})

const isClearPopover = ref(false)

const getDisabledRemoveIcon = (table, itemIdx) => {
  return (
    Object.values(table[itemIdx]).some((item) => !item) &&
    table.length - 1 === itemIdx
  )
}

const clearTable = () => {
  emits('onClearTable')
}
</script>
