var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col justify-between object-sensors__list w-1/3 gap-3 py-4"},[_c('div',{staticClass:"flex flex-col gap-3 h-full overflow-auto"},[_c('div',{staticClass:"flex justify-between items-center pl-5 pr-3"},[_c('div',[_c('el-checkbox',{attrs:{"disabled":_setup.isCheckAllDisabled,"indeterminate":_setup.isIndeterminate},on:{"change":_setup.handleCheckAllChange},model:{value:(_setup.checkAll),callback:function ($$v) {_setup.checkAll=$$v},expression:"checkAll"}}),_c('span',{staticClass:"text-base ml-2"},[_vm._v(_vm._s(_setup.$t('new_units.objects.fields.sensors.title')))])],1),(!_setup.checkAll)?_c('div',{staticClass:"flex justify-between items-center pl-5 pr-3"},[_c('button',{staticClass:"mr-1",attrs:{"data-test-id":"show-modal-sensor","title":"Копировать датчики"},on:{"click":_setup.showModalSensor}},[_c(_setup.AddRectangle,{staticStyle:{"cursor":"pointer"}})],1),_c('button',{staticClass:"ml-1",attrs:{"data-test-id":"add-new-sensor"},on:{"click":function($event){return _vm.$emit('onAdd')}}},[_c(_setup.UnitsAddButton)],1)]):_c(_setup.UnitsDeleteButton,{attrs:{"deleted-count":_setup.checkedSensorsCount.length},on:{"onDelete":_setup.handleDelete}})],1),(_vm.data.length)?_c('div',[_c(_setup.draggable,{staticClass:"draggable-list",attrs:{"disabled":_setup.checkAll,"ghost-class":"ghost","drag-class":"drag","chosen-class":"chosen"},on:{"change":_setup.dragItem},model:{value:(_setup.dataCopy),callback:function ($$v) {_setup.dataCopy=$$v},expression:"dataCopy"}},_vm._l((_vm.data),function(item,idx){return _c('div',{key:item.id,class:[
            'object-sensors__list__item',
            'flex',
            'justify-between',
            'items-center',
            'pl-5',
            'pr-3',
            'cursor-pointer',
            {
              'bg-lightGrey-100': idx % 2 === 0,
              error: _vm.selectedSensor.id === item.id && !!_setup.errors.length,
              hidden: _vm.recovered.includes(item.id)
            }
          ],on:{"mouseover":function($event){return _setup.hoverHandler({ active: true, id: item.id })},"mouseleave":function($event){return _setup.hoverHandler({ active: false, id: item.id })},"click":function($event){return _vm.$emit('onChange', { id: item.id, idx })}}},[_c('div',{staticClass:"flex items-center gap-2"},[(_setup.checkAll)?_c(_setup.UnitsCheckbox,{attrs:{"value":_setup.sensorCheckboxes[idx].checked},on:{"onChange":function($event){return _setup.toogleCheckbox(idx, $event)}},nativeOn:{"click":function($event){$event.stopPropagation();}}}):_c('div',{staticClass:"flex items-center gap-1"},[_c(_setup.DragIcon)],1),(_vm.selectedSensor === item.id)?_c('div',{staticClass:"object-sensors__list__item__active rounded-full"}):_vm._e(),_c('div',{staticClass:"flex flex-col"},[_c('el-tooltip',{attrs:{"open-delay":700,"effect":"dark","content":item.name,"placement":"bottom","offset":2,"visible-arrow":false}},[_c('span',{class:[
                    'max-w-textLong',
                    'truncate',
                    {
                      active: _vm.selectedSensor === item.id,
                      recovered: _vm.recovered.includes(item.id)
                    }
                  ]},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('span',{staticClass:"text-xs text-grey-300"},[_vm._v(" "+_vm._s(_setup.hiddenStatus(item))+" ")])],1)],1),((_setup.isHovered.active && _setup.isHovered.id === item.id) || _vm.selectedSensor === item.id || _vm.recovered.includes(item.id))?_c('div',{staticClass:"object-sensors__list__item__remove cursor-pointer"},[_c(_setup.DeleteIcon,{class:{ recovered: _vm.recovered.includes(item.id) },nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('onDelete', item)}}})],1):_vm._e()])}),0)],1):_c('div',{staticClass:"noSensors"},[_c('div',{staticClass:"noSensors-scroll flex flex-col gap-5 items-center justify-center h-full"},[_c('div',{staticClass:"noSensors-info"},[_c(_setup.NoSensorsIcon),_c('span',{staticClass:"text-center greyToneSecond"},[_vm._v(_vm._s(_setup.$t('new_units.objects.fields.sensors.no_sensors')))])],1),_c('div',{staticClass:"noSensors-buttons flex flex-col gap-2 items-center"},[_c('el-button',{staticClass:"create-sensor",on:{"click":function($event){return _vm.$emit('onAdd')}}},[_vm._v(" "+_vm._s(_setup.$t('create.new_sensor'))+" ")]),_c('el-button',{staticClass:"copy-sensor",on:{"click":function($event){_setup.showModalSensors = !_setup.showModalSensors}}},[_vm._v(" "+_vm._s(_setup.$t('create.copy.another_sensor'))+" ")])],1)])])]),(_vm.recovered.length)?_c(_setup.UnitsDeletedRecovery,{attrs:{"count":_vm.recovered.length,"active-tab":_setup.activeTab},on:{"onCancel":function($event){return _vm.$emit('onCancel')},"onDeleteRecovered":function($event){return _vm.$emit('onDeleteRecovered')}}}):_vm._e(),(_setup.showModalSensors)?_c(_setup.ModalSensors,{on:{"close":function($event){_setup.showModalSensors = false},"onSubmit":function($event){_vm.$emit('addCopySensors', $event), (_setup.showModalSensors = false)}},model:{value:(_setup.showModalSensors),callback:function ($$v) {_setup.showModalSensors=$$v},expression:"showModalSensors"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }