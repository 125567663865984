<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.25 4.58366H5.5V6.41699H16.5V4.58366H13.75L12.8333 3.66699H9.16667L8.25 4.58366ZM15.5833 7.33366H6.41667V16.5003C6.41667 16.9866 6.60982 17.4529 6.95364 17.7967C7.29745 18.1405 7.76377 18.3337 8.25 18.3337H13.75C14.2362 18.3337 14.7025 18.1405 15.0464 17.7967C15.3902 17.4529 15.5833 16.9866 15.5833 16.5003V7.33366Z"
      fill="#A6AEB8"
    />
  </svg>
</template>

<script>
export default {
  name: 'TrashIcon'
}
</script>

<style lang="scss" scoped>
.trash-icon:hover path {
  fill: red; /* Измените на нужный вам цвет */
}
</style>
