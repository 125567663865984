var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-collapse',{staticClass:"object-sensors__details__technical__parameters",on:{"change":_setup.openParam}},[_c('el-collapse-item',[_c('template',{slot:"title"},[_c('div',{staticClass:"flex justify-between items-center w-full"},[_c('div',{staticClass:"flex items-center gap-1"},[_c('span',[_vm._v(" "+_vm._s(_setup.$t( 'new_units.objects.fields.sensors.fields.connected_sensors.title' ))+" ")]),_c('span',{staticClass:"text-grey-200"},[_vm._v(" ("+_vm._s(_setup.connectedSensors.length)+") ")])]),_c('div',{staticClass:"flex justify-center items-center w-5 h-5 rounded-full bg-lightGrey-200"},[_c(_setup.ArrowDownIcon,{class:[
              'arrow-icon',
              'transform',
              _setup.isOpen ? 'rotate-90' : 'rotate-0'
            ]})],1)])]),(_setup.connectedSensors.length)?_c('ul',_vm._l((_setup.connectedSensors),function(item,index){return _c('li',{key:index,staticClass:"valueRow items-center"},[_c('label',{staticClass:"labelRow leading-4",attrs:{"for":"value"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"flex items-center gap-4"},[_c('div',{staticClass:"flex items-center gap-1 rounded-20 bg-lightGrey-200 border border-lightGrey-300 p-1 pr-3"},[_c(_setup.CheckCircleIcon),_c('span',{staticClass:"text-sm text-green-100"},[_vm._v(" "+_vm._s(_setup.$t( 'new_units.objects.fields.sensors.fields.connected_sensors.connected' ))+" ")])],1),_c('span',{staticClass:"text-grey-200 text-xs font-normal cursor-pointer",on:{"click":function($event){return _setup.deleteConnectedSensor(item)}}},[_vm._v(" "+_vm._s(_setup.$t( 'new_units.objects.fields.sensors.fields.connected_sensors.cancel' ))+" ")])])])}),0):_vm._e(),_c('div',{staticClass:"add-tech-params px-4"},[_c(_setup.AddTechParamsIcon,{staticClass:"'add-params-icon'",class:{
          'add-params-icon--grey':
            _setup.connectedSensors.length === _setup.dropdownList.length
        },nativeOn:{"click":function($event){return _setup.openTechFields.apply(null, arguments)}}}),_c('span',{class:[
          'title',
          { 'title--grey': _setup.connectedSensors.length === _setup.dropdownList.length }
        ],on:{"click":_setup.openTechFields}},[_vm._v(" "+_vm._s(_setup.$t( 'new_units.objects.fields.sensors.fields.connected_sensors.bundle_with_ignition' ))+" ")])],1),(_setup.isTechFieldsOpen)?_c('ul',{staticClass:"select-tech-fields"},_vm._l((_setup.dropdownList),function(item){return _c('li',{key:item.id,staticClass:"select-name",class:{
          'text-grey-300 pointer-events-none': _setup.isFieldAlreadyAdded(item)
        },on:{"click":function($event){return _setup.addConnectedSensor(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }