<template>
  <el-collapse
    class="object-sensors__details__technical__parameters mt-6"
    @change="openParam"
  >
    <el-collapse-item>
      <template slot="title">
        <div class="flex justify-between items-center w-full">
          <div class="flex items-center gap-1">
            <span>
              {{ $t('new_units.objects.fields.sensors.fields.technical_params.title') }}
            </span>
            <span class="text-grey-200"> ({{ resultArray.length }}) </span>
          </div>
          <div
            class="flex justify-center items-center w-5 h-5 rounded-full bg-lightGrey-200"
          >
            <ArrowDownIcon
              :class="[
                'arrow-icon',
                'transform',
                isOpen ? 'rotate-90' : 'rotate-0'
              ]"
            />
          </div>
        </div>
      </template>
      <el-form
        v-if="resultArray.length"
        :model="model"
        :ref="refs.sensorTechParametersValidation"
      >
        <ul>
          <li
            v-for="(item, index) in resultArray"
            :key="index"
            class="valueRow items-center"
          >
            <label class="labelRow leading-4" for="value">
              {{ item.name }}:
            </label>

            <div class="flex items-center gap-4">
              <div class="text-field">
                <el-form-item
                  v-if="selectOrInput(item)"
                  :rules="parameterRule"
                  :prop="`value__${item.key}`"
                >
                  <el-select
                    v-model="item.value"
                    class="object-parameters__details--select"
                  >
                    <el-option
                      v-for="option in customField(item.key).values"
                      :key="option.key"
                      :value="option.key"
                      :label="option.value"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item
                  v-else
                  :rules="parameterRule"
                  :prop="`value__${item.key}`"
                >
                  <el-input
                    v-model="item.value"
                    id="value"
                    type="text"
                    @input="validateInput(item, index, 'value')"
                  />
                </el-form-item>
              </div>

              <el-tooltip
                :content="`${$t(
                  'new_units.objects.fields.sensors.fields.technical_params.show_annotation'
                )}`"
              >
                <UnitsSwitch
                  :value="item.show_in_monitoring"
                  class="switchRow"
                  @onChange="changeField($event, index, 'show_in_monitoring')"
                />
              </el-tooltip>

              <TrashIcon
                class="deleteIcon"
                @click.native="deleteFields(item)"
              />
            </div>
          </li>
        </ul>
      </el-form>
      <div class="add-tech-params px-4">
        <AddTechParamsIcon
          class="'add-params-icon'"
          :class="{
            'add-params-icon--grey': props.fields.length === techFields.length
          }"
          @click.native="openTechFields"
        />
        <span
          :class="[
            'title',
            { 'title--grey': props.fields.length === techFields.length }
          ]"
          @click="openTechFields"
        >
          {{
            $t(
              'new_units.objects.fields.sensors.fields.technical_params.add_params'
            )
          }}
        </span>
      </div>
      <ul v-if="isTechFieldsOpen" class="select-tech-fields">
        <li
          v-for="item in techFields"
          :key="item.key"
          class="select-name"
          :class="{ disabled: isFieldAlreadyAdded(item) }"
          @click="addFields(item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </el-collapse-item>
  </el-collapse>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'
import { checkPattern, refs } from '@/components/unitsNew/helpers/index.js'

import ArrowDownIcon from '@/components/unitsNew/assets/icons/arrow-down.vue'
import UnitsSwitch from '@/components/unitsNew/components/UnitsSwitch.vue'
import TrashIcon from '@/components/unitsNew/assets/icons/trash-icon.vue'
import AddTechParamsIcon from '@/components/unitsNew/assets/icons/add-tech-params.vue'

const { useState, useGetters } = createVuexHelpers()
const { customFields } = useState('properties', ['customFields'])
const { customField } = useGetters('properties', ['customField'])

const emits = defineEmits(['onFieldDelete', 'onAddField', 'onChangeTechField'])

const props = defineProps({
  fields: {
    type: Array,
    default: () => []
  },
  typeKey: {
    type: String,
    default: ''
  }
})
const $t = useI18n()

const isOpen = ref(false)
const isTechFieldsOpen = ref(false)

const model = ref({})
const invalidFields = ref([])

const selectProps = [
  'fuel_level_use_raw_data',
]

const selectOrInput = (item) => {
  return selectProps.includes(item.key)
}

const getPropValues = () => {
  props.fields.forEach((field) => {
    model.value['value__' + field.key] = field.value
  })
}

const validateInput = (field, index, prop) => {
  changeField(field.value, index, prop)

  const isValid = checkPattern(field.pattern, field.value)

  !isValid
    ? invalidFields.value.push(`value__${field.key}`)
    : (invalidFields.value = invalidFields.value.filter(
        (item) => item !== `value__${field.key}`
      ))

  refs.sensorTechParametersValidation.value.validateField(`value__${field.key}`)
}

const validateField = (rule, value, callback) => {
  if (!invalidFields.value.includes(rule.field)) {
    callback()
  } else {
    callback(new Error())
  }
}

const parameterRule = [
  {
    validator: validateField,
    message: $t(
      'new_units.objects.fields.sensors.fields.technical_params.errors.wrong_value'
    )
  }
]

const openParam = () => {
  isOpen.value = !isOpen.value
  isTechFieldsOpen.value = false
}

const openTechFields = () => {
  if (!techFields.value.length) return
  isTechFieldsOpen.value = !isTechFieldsOpen.value
}

const addFields = (item) => {
  const field = {
    name: item.name,
    value: item.default_value,
    key: item.key,
    show_in_monitoring: item.show_in_monitoring,
    pattern: item.pattern
  }

  emits('onAddField', field)
}

const deleteFields = (field) => {
  const index = props.fields.findIndex((item) => item.key === field.key)

  if (index !== -1) {
    invalidFields.value = invalidFields.value.filter(
      (item) => item !== `value__${field.key}`
    )

    emits('onFieldDelete', index)
  }
}

const isFieldAlreadyAdded = (item) => {
  return props.fields.some((field) => field.key === item.key)
}

const changeField = (item, index, prop) => {
  emits('onChangeTechField', { value: item, index, prop })
}

const techFields = computed(() => {
  return customFields.value.filter((item) =>
    item.sensors.includes(props.typeKey)
  )
})

const resultArray = computed(() => {
  getPropValues()
  const arr = props.fields.map((item2) => {
    const matchingItem1 = techFields.value.find(
      (item1) => item1.key === item2.key
    )

    if (matchingItem1) {
      item2.pattern = matchingItem1.pattern

      return item2
    }

    return item2
  })

  return arr
})

watch(techFields, (val) => {
  if (!val.length) isTechFieldsOpen.value = false
})
</script>
